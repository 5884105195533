import { call, put, takeLatest } from 'redux-saga/effects';

import network from 'network';
import { setTokenCookies, removeTokenCookies } from 'network/cookies';

import { userAuthAction, clearAuth } from 'store/auth/actions';
import { TAuthRequest } from 'store/auth/entities';

function* userAuthSaga({ payload }: { payload: TAuthRequest }) {
  try {
    const token = yield call([network, network.userAuth], payload);
    yield call(setTokenCookies, token.token);
    yield put(userAuthAction.success(token.token));
  } catch (e) {
    yield put(userAuthAction.failure(e));
    // eslint-disable-next-line no-alert
    yield alert(`Ошибка: ${e.message}`);
  }
}

function* clearAuthSaga() {
  yield call(removeTokenCookies);
}

export function* watchAuth() {
  yield takeLatest(userAuthAction.request, userAuthSaga);
  yield takeLatest(clearAuth, clearAuthSaga);
}
