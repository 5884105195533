import { createReducer, getType } from 'typesafe-actions';
import { clearAuth, userAuthAction } from 'store/auth/actions';
import { TAuth } from 'store/auth/entities';

const initialState: TAuth = {
  token: null,
};

const authReducer = createReducer<TAuth>(initialState, {
  [getType(clearAuth)]: () => ({
    ...initialState,
    token: null,
  }),
  [getType(userAuthAction.request)]: () => ({
    ...initialState,
  }),
  [getType(userAuthAction.success)]: (state, { payload }) => ({
    ...state,
    token: payload,
  }),
  [getType(userAuthAction.failure)]: () => ({
    ...initialState,
  }),
});

export default authReducer;
