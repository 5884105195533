import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { ThemeProvider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import theme from 'theme';
import { getTokenCookies } from 'network/cookies';

import { reducer } from 'store/reducer';
import rootSaga from 'store/rootSaga';
import { userAuthAction } from 'store/auth/actions';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

const token = getTokenCookies();

sagaMiddleware.run(rootSaga);
if (token) {
  store.dispatch(userAuthAction.success(token));
}

const LazyRouter = lazy(() =>
  import('./containers/Router').then(({ RouterContainer }) => ({
    default: RouterContainer,
  })),
);

const container = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LinearProgress />}>
          <LazyRouter />
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  container,
);
