export type TNetworkError = {
  status: number;
  message: string;
  title?: string;
};

export class NetworkError {
  readonly status: number;

  readonly message: string;

  readonly title: string;

  readonly subTitle: string;

  constructor({ message, status, title }: TNetworkError) {
    this.status = status;
    this.message = message;
    this.title = title || '';
  }
}
