// @ts-nocheck
import { createMuiTheme } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Ubuntu',
      'Helvetica Neue',
      'sans-serif',
    ],
  },
  palette: {
    primary: {
      contrastText: '#fff',
      dark: 'rgb(17, 82, 147)',
      light: 'rgb(71, 145, 219)',
      main: '#1976d2',
    },
    secondary: {
      contrastText: '#fff',
      dark: 'rgb(154, 0, 54)',
      light: 'rgb(227, 51, 113)',
      main: 'rgb(220, 0, 78)',
    },
    error: {
      contrastText: '#fff',
      dark: '#d32f2f',
      light: '#e57373',
      main: '#f44336',
    },
    warning: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#f57c00',
      light: '#ffb74d',
      main: '#ff9800',
    },
    info: {
      contrastText: '#fff',
      dark: '#1976d2',
      light: '#64b5f6',
      main: '#2196f3',
    },
    success: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50',
    },
  },
}, ruRU);

export default theme;
