import { createAction, createAsyncAction } from 'typesafe-actions';

import { TNetworkError } from 'network/Instance/errors';

import { TAuthRequest } from 'store/auth/entities';

export const CLEAR_AUTH = '@auth/CLEAR_AUTH';
export const clearAuth = createAction(CLEAR_AUTH)();

export const USER_AUTH_REQUEST = '@auth/USER_AUTH_REQUEST';
export const USER_AUTH_SUCCESS = '@auth/USER_AUTH_SUCCESS';
export const USER_AUTH_FAILURE = '@auth/USER_AUTH_FAILURE';
export const userAuthAction = createAsyncAction(
  [USER_AUTH_REQUEST, (requestData: TAuthRequest) => requestData],
  [USER_AUTH_SUCCESS, (token: string) => token],
  [USER_AUTH_FAILURE, (error: TNetworkError | null) => error],
)();
