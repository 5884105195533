import Instance, { TAxios } from 'network/Instance';
import { TAuthRequest } from 'store/auth/entities';

class Network extends Instance {
  constructor(baseURL: string, timeout = 30000) {
    super(baseURL, timeout);
  }

  async userAuth(payload: TAuthRequest): TAxios {
    return this.send('post', '/ladum/token/generate', payload);
  }
}

// @ts-ignore
// eslint-disable-next-line no-undef
const { REACT_APP_MOCK_SERVER_URL } = proc.env;

const network = new Network(REACT_APP_MOCK_SERVER_URL);
export default network;
